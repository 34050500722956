import React from "react"
import Layout from "../components/layout";

const divStyle = {
  display: 'flex',
  justifyContent: 'center',
  fontSize: '3rem'

};

const NotFoundPage = () => (
    <Layout>
      <div style={divStyle}>
        <h1>Error 404</h1>
      </div>
    </Layout>

)

export default NotFoundPage
