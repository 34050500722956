import React, {Component} from 'react';
import './footer.scss'
import Logo from '../../images/poze/logo.png'

import {FaPhone} from "react-icons/fa/index";
import {FaMapPin} from "react-icons/fa/index";

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container footer-content">
                    <div className="footer-logo">
                        <img src={Logo} alt="logo"/>
                    </div>
                    <div className="footer-info">
                        <div className="footer-contacts" id="contacts">
                            <p>For any questions contact: <a href="mailto:contacts@amarela.io" className="footer-email">contacts@amarela.io</a></p>
                            <ul className="contacts-details">
                                <li><a href="tel:+37368080180" className="list-item"> <div className="footer-icon"><FaPhone /></div>  +37368080180</a></li>
                                <li><a href="https://www.google.com/maps/@47.0389421,28.8259823,19z" target="_blank" rel="noopener noreferrer"  className="list-item"> <div className="footer-icon"><FaMapPin/></div>  Address: Columna 170, Chișinău, MD - 2004, Moldova</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
