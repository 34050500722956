import React,{Component} from 'react';
import logo from '../../images/poze/logo.png';
import './header.scss';
import {Link} from "gatsby";
import {FaTimes} from "react-icons/fa"

class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            navbarClass: '',
            focusDiv: 'hidden-navbar'
        }
    }

    collapseNavbar = () => {
        if(this.state.navbarClass === 'navbar-list-collapse' && this.state.focusDiv === 'focus-div' ) {
            this.setState({ navbarClass: '',focusDiv: 'hidden-navbar'});
        } else {
            this.setState({ navbarClass: 'navbar-list-collapse',focusDiv: 'focus-div' });
        }
    }

    closeNavbar = () => {
        this.setState({ navbarClass: '',focusDiv: 'hidden-navbar'});
    }

    render() {

    return (
        <header className="header" >
            <div className="container">
                <div className="header-block">
                    <div className="menu-collapse" onClick={this.collapseNavbar} >
                        <div className="bar w1"></div>
                        <div className="bar w2"></div>
                        <div className="bar w3"></div>
                    </div>
                    <a href="/"><img src={logo} alt="logo" className="logo-png"/></a>
                    <div className={this.state.focusDiv} onClick={this.closeNavbar}>
                        <div className={this.state.navbarClass}>
                            <FaTimes className="navbar-exit" />
                            <Link to="#about-us-block">Who we are</Link>
                            <Link to="#occupation">What we do</Link>
                            <Link to="#skills">Our skills</Link>
                            <Link to="/work-with-us">Work with us</Link>
                            <Link to="#contacts">Contacts</Link>
                        </div>
                    </div>
                    <nav>
                        <ul className="navbar-list" >
                            <li><Link to="#about-us-block">Who we are</Link></li>
                            <li><Link to="#occupation">What we do</Link></li>
                            <li><Link to="#skills">Our skills</Link></li>
                            <li><Link to="/work-with-us">Work with us</Link></li>
                            <li><Link to="#contacts">Contacts</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
  )
  }
}

export default Header
