import React, {Component} from 'react';
import Header from "./header/header"
import "./layout.scss"
import Footer from "./footer/footer";

class Layout extends Component {
    render() {
        const {children} = this.props;
        return (
            <>
        <Header/>
        <main className="main-block">
            {children}
        </main>
        <Footer/>
      </>
    );
    }
}

export default Layout;
